import React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/layout"
import {RecyclingQuery} from "./__generated__/RecyclingQuery"


export default ({ data, location }: PageProps<RecyclingQuery>) => {

    return (
        <Layout
            seo={{
                title: "Recycling",
            }}
            location={location}
        >
            <div className="container mx-auto py-12">
                <div className="title py-12 text-center">
                    <h2 className="font-black text-5xl text-color-1">
                        Recycing
                    </h2>
                </div>
                <div className="flex flex-wrap pb-40"></div>
            </div>
        </Layout>
    )
}

export const query = graphql`
           query RecyclingQuery {
               mdx {
                   body
                   frontmatter {
                       title
                   }
               }
           }
       `
